import React, { useEffect, useState } from "react";
import { Element } from "react-scroll";
import ScrollNav from "./PlaceDetails/ScrollNav";
import DisplayImages from "./PlaceDetails/DisplayImages.jsx";
import Address from "./PlaceDetails/Address";
import MesEquipements from "./PlaceDetails/MesEquipements.jsx";
import PropertyInfoUpdate from "./PlaceDetails/PropertyInfoUpdate.jsx";
import UpdateTypeBein from "./PlaceDetails/UpdateTypeBein.jsx";
import UpdateConditionsAnnulation from "./PlaceDetails/UpdateConditionsAnnulation.jsx";
import Topbar from "../ui/Topbar";
import GoBack from "../ui/GoBack.jsx";
import axios from "axios";
import TitleAndDescription from "./Title&Disctiption.jsx";
import GrayLine from "../ui/GrayLine.jsx";
import UpdateTypeOfTravel from "./PlaceDetails/UpdateTypeOfTravel.jsx";
import UpdateTypeOfBeinMorP from "./PlaceDetails/UpdateTypeOfBeinMorP.jsx";
import UpdateAirbnbLinks from "./PlaceDetails/UpdateAirbnbLinks.jsx";
import UpdatePolitiques from "./PlaceDetails/UpdatePolitiques.jsx";
import Cookies from "js-cookie";
import SeoHelmet from "../../seo/SeoHelmet.jsx";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../Navbar.jsx";
import { useTranslation } from "react-i18next";

function HostpropertyDetails() {
  const [place, setPlace] = useState(null);
  const [Title, setTitle] = useState(null);
  const [Description, setDescription] = useState(null);
  const [Conditions, setConditions] = useState(null);
  const [Visibilite, setVisibilite] = useState(null);
  const [props, setProps] = useState([]);

  const { id , lang } = useParams();
  const { t, i18n } = useTranslation();
  useEffect(()=>{
    if(lang && (lang == "en" ||lang =="fr" || lang == "es" )){
      console.log("lang : ", lang);
      i18n.changeLanguage(lang);
    }
  },[])

  const [Images, setImages] = useState([
    { id: 1, img: null },
    { id: 2, img: null },
    { id: 3, img: null },
    { id: 4, img: null },
    { id: 5, img: null },
  ]);
  const [DisplayedAddres, setDisplayedAddres] = useState("");
  const [LngAndLatOfMap, setLngAndLatOfMap] = useState(null);
  const [ID_Bien, setID_Bien] = useState(null);
  const [Slog, setSlog] = useState(null);

  const [addressDetails, setAddressDetails] = useState({
    country: "",
    streetAddress: "",
    Gouvernorat: "",
    Delegation: "",
    postalCode: "",
  });

  const [TravelType, setTravelType] = useState({
    TypeDeVoyageTravail: 0,
    TypeDeVoyageDetente: 0,
    TypeDeVoyageSAmuser: 0,
    TypeDeVoyageDecouvrir: 0,
  });

  const [roles, setRoles] = useState({
    evenementsautorises: false,
    autorisationdefumer: false,
    animauxautorises: false,
  });

  const [BeinType, setBeinType] = useState(null);

  const [Links, setLinks] = useState({
    link01: null,
    link02: null,
  });

  const [numChombreAndList, setNumChombreAndList] = useState({
    NbVoyageurs: null,
    NbChambres: null,
    NbLits: null,
    NbSallesDeBain: null,
  });

  const [BienInfo, setBienInfo] = useState(null);

  const NavTo = useNavigate();

  useEffect(() => {
    const getTravelTypeLabel = (typeKey) => {
      switch (typeKey) {
        case "TypeDeVoyageTravail":
          return "Voyage d'affaires";
        case "TypeDeVoyageDecouvrir":
          return "Voyage de découverte";
        case "TypeDeVoyageDetente":
          return "Voyage de détente";
        case "TypeDeVoyageSAmuser":
          return "Voyage pour s'amuser";
        default:
          return "Type de voyage inconnu";
      }
    };
    // Function to get the keys with value 1 or true
    const getSelectedTravelTypes = () => {
      return Object?.entries(TravelType)
        .filter(([key, value]) => value === 1 || value === true)
        .map(([key]) => key);
    };
    // Usage example
    const selectedTravelTypes = getSelectedTravelTypes();

    const getSelectedProp = () => {
      return Object?.entries(props)
        ?.filter(([key, value]) => value === 1 || value === true)
        ?.map(([key]) => key);
    };

    // Usage example
    const SelectedProp = getSelectedProp();
    // Get descriptive labels for all selected travel types
    const selectedTravelTypesLabels =
      selectedTravelTypes?.map(getTravelTypeLabel);
    // Concatenate the labels into a single string
    const travelTypeLabelsString = selectedTravelTypesLabels?.join(" , ");
    setBienInfo(`
    Type de voyage: ${travelTypeLabelsString}
      Délégation: ${addressDetails.province}
      Gouvernorat: ${addressDetails.city}
      Adresse: ${addressDetails.streetAddress}
    Type de propriété: ${BeinType}
    Caractéristique: ${SelectedProp} 
  `);
  }, [props, BeinType, addressDetails, TravelType]);

  useEffect(() => {
    if (!id) return;
    const fetchData = async () => {
      const userToken = Cookies.get("Token");
      const UserID = localStorage.getItem("ID_Utulisateur");
      try {
        const response = await axios.post(
          `https://visiontunisie.com/api/v1/GestionDesBiens/SelectBienID`,
          {
            Slog: id,
            ID_Utulisateur: UserID,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        setSlog(response?.data?.Biens?.Slog);
        setLinks({
          Link01: response?.data?.Biens?.URLairBnb,
          Link02: response?.data?.Biens?.URLbooking,
        });
        setPlace(response?.data);
        setVisibilite(response?.data?.Biens?.Visibilite);
        setNumChombreAndList({
          NbVoyageurs: response?.data?.Biens?.NbVoyageurs,
          NbChambres: response?.data?.Biens?.NbChambres,
          NbLits: response?.data?.Biens?.NbLits,
          NbSallesDeBain: response?.data?.Biens?.NbSallesDeBain,
        });
        setID_Bien(response?.data?.Biens?.ID_Bien);
        setTitle(response?.data?.Biens?.Titre);
        setDescription({
          description: response?.data?.Biens?.Description,
          descriptionEn: response?.data?.Biens?.DescriptionAnglais,
          descriptionEs: response?.data?.Biens?.DescriptionSpanish,
        });
        setDisplayedAddres(response?.data?.Biens?.AdresseLigne1);
        setAddressDetails({
          country: response?.data?.Biens?.AdresseLigne4 || "",
          streetAddress: response?.data?.Biens?.AdresseLigne1 || "",
          Gouvernorat: response?.data?.Biens?.Gouvernorat || "",
          Delegation: response?.data?.Biens?.Delegation || "",
          postalCode: "",
        });
        setConditions(response?.data?.Biens?.ConditionsAnnulation);
        setBeinType(response?.data?.Biens?.TypeDeLogement);
        setRoles({
          animauxautorises: response.data.Caracteristiques.animauxautorises,
          autorisationdefumer:
            response.data.Caracteristiques.autorisationdefumer,
          evenementsautorises:
            response.data.Caracteristiques.evenementsautorises,
        });
        setTravelType({
          TypeDeVoyageTravail: response?.data?.Biens?.TypeDeVoyageTravail,
          TypeDeVoyageDetente: response?.data?.Biens?.TypeDeVoyageDetente,
          TypeDeVoyageSAmuser: response?.data?.Biens?.TypeDeVoyageSAmuser,
          TypeDeVoyageDecouvrir: response?.data?.Biens?.TypeDeVoyageDecouvrir,
        });
        const updatedImages = response?.data?.ImagesBien?.map(
          (fetchedImage, index) => ({
            id: index + 1,
            img: fetchedImage.UrlImage,
          })
        );
        setProps(response?.data?.Caracteristiques);
        // Update the state with the new images
        setImages(updatedImages);
      } catch (error) {
        NavTo("/dashboard");
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className=" flex flex-col pb-44 lg:relative fixed h-full w-full z-[70] lg:z-40 overflow-auto top-0 right-0 bg-white ">
      <SeoHelmet
        pageTitle="Host property Details"
        pageType="website"
        pageImage={
          "https://imagedelivery.net/tfItMNmc0m4jZ4qP_DhHCg/07db22d4-d4f9-41f7-a1f5-2bde8a909600/public"
        } 
      />

      <span className=" w-full  hidden  lg:block">
        <Navbar dashboard={true} NotFixed={true} />
      </span>

      <span className="  lg:hidden bolck  w-full ">
        <Topbar goBack={true} text={Title} />
      </span>
      <ScrollNav />
      <div className=" pt-5 px-[1rem]  flex flex-col gap-8 lg:px-[8%] ">
        {/* goBack */}
        <GoBack isLink={true} Titel={Title} />
        <Element name="Detail" className="element">
          <TitleAndDescription
            id={id}
            Slog={Slog}
            BienInfo={BienInfo}
            Title={Title}
            Description={Description}
            setDescription={setDescription}
            setTitle={setTitle}
          />
        </Element>

        <GrayLine />

        <Element name="photos" className="element">
          <DisplayImages
            Slog={Slog}
            id={id}
            Images={Images}
            setImages={setImages}
          />
        </Element>

        <GrayLine />

        <Element name="Adresse" className="element">
          <Address
            Slog={Slog}
            DisplayedAddres={DisplayedAddres}
            setDisplayedAddres={setDisplayedAddres}
            LngAndLatOfMap={LngAndLatOfMap}
            setLngAndLatOfMap={setLngAndLatOfMap}
            addressDetails={addressDetails}
            setAddressDetails={setAddressDetails}
          />
        </Element>
        <GrayLine />

        <UpdateTypeOfTravel
          props={props}
          Slog={Slog}
          travelType={TravelType}
          setTravelType={setTravelType}
        />
        <GrayLine />

        <UpdateTypeBein Slog={Slog} setType={setBeinType} Type={BeinType} />
        <GrayLine />
        <UpdateConditionsAnnulation
          Slog={Slog}
          Conditions={Conditions}
          setConditions={setConditions}
        />
        <GrayLine />
        {Visibilite != "pending" && (
          <UpdateTypeOfBeinMorP
            Slog={Slog}
            Visibilite={Visibilite}
            setVisibilite={setVisibilite}
          />
        )}
        <GrayLine />

        <UpdatePolitiques
          ID_Bien={ID_Bien}
          Slog={Slog}
          roles={roles}
          setRoles={setRoles}
        />

        <GrayLine />

        <UpdateAirbnbLinks Slog={Slog} Links={Links} setLinks={setLinks} />
        <GrayLine />

        <Element name="equipements" className="element">
          <PropertyInfoUpdate
            Slog={Slog}
            setNumChombreAndList={setNumChombreAndList}
            numChombreAndList={numChombreAndList}
          />
        </Element>
        <GrayLine />
        <Element name="equipements" className="element">
          <MesEquipements
            Slog={Slog}
            ID_Bien={ID_Bien}
            setProps={setProps}
            proposee={props}
          />
        </Element>
      </div>
    </div>
  );
}

export default HostpropertyDetails;
