import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';


function Topbar({ text, onClick, goBack , transparnt }) {
  const navto = useNavigate();
  return (
    <div className= {` w-full  z-[60] ${transparnt ?'  text-white border-0' :'bg-white  border-b-[1px]  ' }  py-5 relative     items-center justify-center   font-bold gap-4  border-solid lg:hidden flex flex-row  `}  >
      <span
        className= {` ${transparnt ?'text-white':'  text-gray-500'}  absolute top-5 left-3 `}
        onClick={() => navto('/search-by-map')}
     >
        {goBack ? (
          <ArrowBackIosNewIcon   className="  cursor-pointer    " />
        ) : (
          <CloseIcon  className="  cursor-pointer     " />
        )}
      </span>
      {text}
    </div>
  );
}

export default Topbar;
