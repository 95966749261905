import React, { useContext, useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import "../App.css";
import { useTranslation } from "react-i18next";
import { Usercontext } from "../context/Usercontexte";
import "./Navbar.css";
import {
  BookmarkSimple,
  CalendarBlank,
  ChartBar,
  Chats,
  Heart,
  House,
  HouseLine,
  List,
  MagnifyingGlass,
  UserCircle,
  UserGear,
} from "phosphor-react";
import SuperHostLogo from "./ui/SuperHostLogo";
import { SocketContext } from "../context/SocketContext";

function MobileMenuBar({ NotFixed, dashboard }) {
  const { t, i18n } = useTranslation();
  const { User, setUser, Msgs } = useContext(Usercontext);
  const { pathname } = useLocation();
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);

  const location = useLocation();

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setHasScrolled(prevScrollPos > currentScrollPos);
    setPrevScrollPos(currentScrollPos);
  };

  const NavTo = useNavigate();
  const { messages } = useContext(SocketContext);

  const { id } = useParams();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const hiddenPaths = [
    "/profile/securite",
    "/profile/generalinfo",
    "/host",
    !dashboard && "/dashboard",
    `/messages/${id}`,
    `/dashboard/messages/${id}`,
    !dashboard && "/search-by-map",
    "/payment",
    "/become-host",
    !dashboard && "/messages",
  ];

  // Check if the current path is in the hiddenPaths array
  const isHiddenPage = hiddenPaths.some((path) =>
    location.pathname.includes(path)
  );
  // Check if the current route path contains "/dashboard"
  const isDashboard = location.pathname.includes("/dashboard");

  const GetIsActive = (path) => location.pathname === path;


  const Links = !isDashboard
    ? [
        {
          text: t("NavBar.Accommodation"),
          link: "/search-by-map",
          icon: <MagnifyingGlass weight="thin" size={28} />,
          isActive: GetIsActive(`/search-by-map`),
        },
        {
          text: t("Profile.Favorites"),
          link: "/profile/favoris",
          icon: <Heart weight="thin" size={28} />,
          isActive: GetIsActive(`/profile/favoris`),
        },
        User && {
          text: "Home",
          link: "/",
          icon: <House weight="thin" size={28} />,
          isActive: GetIsActive(`/`),
        },
        !User && {
          text: "Login",
          link: "/auth",
          icon: <UserCircle weight="thin" size={28} />,
          isActive: GetIsActive(`/auth`),
        },
        User && {
          text: t("NavBar.MenuBar.Messages"),
          link: "/messages",
          icon: <Chats weight="thin" size={28} />,
          notification:   Msgs[Msgs?.length - 1 ]?.Allunseen_message || messages?.length ,
          isActive: GetIsActive(`/messages`),
        },
        User && {
          text: t("NavBar.MenuBar.Profile"),
          link: "/profile",
          icon: <UserCircle weight="thin" size={28} />,
          isActive: GetIsActive(`/profile`),
        },
      ]
    : [
        {
          text: "Calendrier",
          link: `/dashboard/calendrier`,
          icon: <CalendarBlank weight="thin" size={28} />,
          isActive: GetIsActive(`/dashboard/calendrier/${id}`),
        },

        {
          text: "Listing",
          link: "/dashboard",
          icon: <HouseLine weight="thin" size={28} />,
          isActive: GetIsActive(`/dashboard`),

        },
        {
          text: "Messages",
          link: "/dashboard/messages",
          icon: <Chats weight="thin" size={28} />,
          notification:  Msgs[Msgs?.length - 1 ]?.Allunseen_message || messages?.length ,
          isActive: GetIsActive(`/dashboard/messages`),

        },
        {
          text: "Menu",
          link: "/dashboard/menu",
          icon: <List weight="thin" size={28} />,
          isActive: GetIsActive(`/dashboard/menu`),

        },
      ];

  return (
    <div
      className={`fixed  ${
        isHiddenPage || (!hasScrolled && prevScrollPos != 0)
          ? " bottom-[-100%] "
          : "bottom-0"
      } duration-300 lg:hidden z-[60]  items-center right-0 h-[70px] w-full bg-white flex  border-t-[1px] ${
        isDashboard ? " px-12 " : " px-[1.2rem]"
      }   ${User ? " justify-between" : "  hidden "} `}
    >
      {Links?.map((link , index) => (
        <div
        key={index}
        
        >
          {link?.link ? (
            <Link
              to={link?.link}
              className={`  ${
                link?.isActive? " text-sec" : ""
              } text-[.7rem] flex flex-col items-center gap-[.18rem]`}
            >
              {link?.icon}
              <h5 className=" relative">
                {link?.text}

                {link?.notification > 0 && (
                  <span className=" z-30 text-white bg-sec flex items-center justify-center text-sm h-[1.1rem] w-[1.1rem] -top-8 -right-0 absolute rounded-[50%] ">
                    {link?.notification}
                  </span>
                )}
              </h5>
            </Link>
          ) : null}
        </div>
      ))}
    </div>
  );
}

export default MobileMenuBar;
