// SocketContext.js
import Cookies from "js-cookie";
import React, { createContext, useState, useEffect, useRef } from "react";
import io from "socket.io-client";

// Import the sound file
import notificationSound from "../assest/nitificationSound.mp3"; // adjust the path as necessary

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [sendrMessages, setSendrMessages] = useState([]);
  const socketRef = useRef();
  const userToken = Cookies.get("Token");

  // Ref to store AudioContext for autoplay workaround
  const audioContextRef = useRef(null);
  const audioBufferRef = useRef(null);


  useEffect(() => {
    const initializeSocket = async () => {
      socketRef.current = io("https://realtime.visiontunisie.com");
  
      socketRef.current.on("connect", () => {
        socketRef.current.emit("register", { token: userToken });
      });
  
      socketRef.current.on("message", (data) => {
   
        // Add the message to the state
      // setMessages((prevMessages) => [...(prevMessages || []), data]);
        const audio = new Audio(notificationSound);
        audio.play();
   
      });
    };
  
    initializeSocket();
  
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [userToken]);
  

  const playNotificationSound = () => {
    if (audioContextRef.current && audioBufferRef.current) {
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContextRef.current.destination);
      source.start(0);
    } else {
      console.warn("Audio context or buffer is not initialized.");
    }
  };

  const sendMessage = (message, conversationUID) => {
    if (socketRef.current && userToken) {
      socketRef.current.emit("private_message", {
        token: userToken,
        conversationUID: conversationUID,
        message: message,
      });
    }
  };

  return (
    <SocketContext.Provider
      value={{
        userToken,
        messages,
        sendMessage,
        setMessages,
        sendrMessages,
        setSendrMessages,
        socket: socketRef.current,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
