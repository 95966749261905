import React, { useContext, useRef, useState } from "react";
import UserPic from "../../assest/img/userPic.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GrayLine from "../ui/GrayLine";
import { Link, useLocation } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import { useClickOutside } from "../../hooks/UseClickOutSide";
import { SocketContext } from "../../context/SocketContext";

function UserDropDown({
  UserName,
  LogOut,
  UserImg,
  Msgs,
  setHasProperty,
  hasProperty,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const DropDownRef = useRef();
  useClickOutside(DropDownRef, () => setIsOpen(false));
  const location = useLocation();
  const isDashboard = location.pathname.includes("/dashboard");
  const { messages } = useContext(SocketContext);

  const { t } = useTranslation();
  const links = [
    // { to: "/", title: t('NavBar.MenuBar.Notification')},
    // { to: "/profile", title: t("NavBar.MenuBar.Profile") },

    !isDashboard && { to: "/profile", title: t("NavBar.MenuBar.Profile") },
    !isDashboard && {
      to: "/messages",
      title: t("NavBar.MenuBar.Messages"),
      notification:  Msgs[Msgs?.length - 1 ]?.Allunseen_message || messages?.length ,
    },
    !isDashboard && {
      to: "/profile/favoris",
      title: t("NavBar.MenuBar.Favorites"),
    },
  
    isDashboard && {
      to: "/auth/sign-up",
      title: hasProperty
        ? t("NavBar.MenuBar.createNewListing")
        : t("NavBar.BecomeHost"),
    },
    isDashboard && {
      to: "/dashboard/channelmanager",
      title: t('NavBar.ChannelMannager'),
    },
    isDashboard && {
      to: "/dashboard/demande-versement",
      title: t("Revenues.PaymentRequest"),
    },
 
    !isDashboard && { to: "/profile/historique", title: t("Labels.MyBooking") },
  ];

  return (
    <div className="  lg:w-[14rem] hover:shadow-md group lg:border-l-2  border-sec px-2 p-1    cursor-pointer relative flex items-center  justify-between ">
      <span
        ref={DropDownRef}
        onClick={() => setIsOpen(!isOpen)}
        className=" flex  gap-2 items-center"
      >
        {!UserImg ? (
          <span
            onClick={() => setIsOpen(!isOpen)}
            className=" flex  items-center h-[40px]  w-[40px]  z-10 bg-gray-200 rounded-[50%]   justify-center"
          >
            <PersonIcon className=" scale-150 text-white" />
          </span>
        ) : (
          <img
          height={200}
          loading="auto"
          title="User Image"
          width={200}
            onClick={() => setIsOpen(!isOpen)}
            className=" h-[40px] object-cover  w-[40px] rounded-[50%]  z-10 "
            src={UserImg}
            alt="User-image"
          />
        )}

        <h1 className="  hidden lg:flex">{UserName}</h1>
      </span>

      <span className=" hidden lg:flex  duration-150 rotate-0 group-hover:rotate-180">
        <KeyboardArrowDownIcon />
      </span>

      <div
        className={`z-20 absolute ${
          isOpen
            ? "  lg:shadow-none shadow-lg lg:h-0 h-[16.3rem]"
            : " lg:h-0 h-[0rem]"
        } top-12 right-0 bg-white group-hover:shadow-lg   w-fit rounded-md  lg:w-full  ${
          !isDashboard
            ? "lg:group-hover:h-[14.6rem]  "
            : "lg:group-hover:h-[11.5rem]  "
        } duration-200 overflow-hidden  flex flex-col gap-3`}
      >
        <span className=" px-4 pt-3  hidden group-hover:flex w-full ">
          <GrayLine />
        </span>
        <span className="px-2 py-1 flex flex-col gap-4">
          {links.map((link, index) =>
            link.to ? (
              <Link
                key={index}
                to={link.to}
                className="whitespace-nowrap hover:text-sec font-medium relative"
              >
                <span className=" w-fit relative">
                  {link.title}
                  {link?.notification > 0 && (
                    <span className="  text-white bg-sec flex items-center justify-center text-sm h-[1.1rem] w-[1.1rem] top-1 -right-5 absolute rounded-[50%] ">
                      {link?.notification}
                    </span>
                  )}
                </span>
              </Link>
            ) : null
          )}
          {UserName && (
            <Link className="lg:hidden flex" to={"/dashboard"}>
               {t('NavBar.HostMode')}
            </Link>
          )}
          <button
            onClick={LogOut}
            className="text-left hover:text-sec font-medium"
          >
            Deconnexion
          </button>
        </span>
      </div>
    </div>
  );
}

export default UserDropDown;
