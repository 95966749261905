import React from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
function ScrollNav({ setEdit, Edit }) {
  const { t } = useTranslation();
//t("Msgs.Updatesuccessful", { text: t("Labels.Equipment") })
  const Links = [
    {
      text: t("HostMode.NavLogememntDetails"),
      link: "Detail",
    },
    {
      text: t("HostMode.NavLogememntPictures"),
      link: "photos",
    },
    {
      text: t("HostMode.NavLogememntAdress"),
      link: "Adresse",
    },
    {
      text: t("HostMode.NavLogememntMyEquipments"),
      link: "equipements",
    },
  ];

  return (
    <span className=" sticky top-0 text-gray-400 w-full h-[5rem] bg-gray-100 lg:flex hidden flex-row gap-10 items-center justify-center ">
      {Links.map((link,index) => (
        <Link
          smooth={true}
          duration={500}
          to={`${link.link}`}
          key={index}
          className=" cursor-pointer"
        >
          {link.text}
        </Link>
      ))}

     
    </span>
  );
}

export default ScrollNav;
