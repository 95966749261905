  import React, { lazy, Suspense, useContext } from "react";
import { Route, Routes   } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./component/Footer";
import MobileMenuBar from "./component/MobileMenuBar";
import "./App.css";
import { Usercontext } from "./context/Usercontexte.js";
import { OfflineModal } from "./component/ui/OfflineModal.jsx";
import useNetworkStatus from "./hooks/UseNetworkStatus.js";
import RouteLoading from "./component/ui/RouteLoading.jsx";
import HostpropertyDetails from "./component/Dashboard/PlaceDetails.jsx";
import Calendrier from "./component/Dashboard/Calendrier.jsx";
// Lazy load your components
const Home = lazy(() => import("./pages/Home"));
const BienDetails = lazy(() => import("./pages/BienDetails.jsx"));
const Auth = lazy(() => import("./pages/Auth.jsx"));
const Payment = lazy(() => import("./pages/Payment.jsx"));
const Host = lazy(() => import("./pages/Host.jsx"));
const Logement = lazy(() => import("./pages/Logement.jsx"));
const TravelType = lazy(() => import("./pages/TravelType.jsx"));
const BecomeHost = lazy(() => import("./pages/BecomeHost.jsx"));
const Profile = lazy(() => import("./pages/Profile.jsx"));
const OtherUserProfile = lazy(() => import("./pages/otherUserProfile.jsx"));
const Dashboard = lazy(() => import("./pages/Dashboard.jsx"));
const SearchBienByMap = lazy(() => import("./pages/SearchBienByMap.jsx"));
const FriendTrip = lazy(() => import("./pages/FriendTrip.jsx"));
const PartenairesDetails = lazy(() => import("./pages/PartenairesDetails.jsx"));
const Verify = lazy(() => import("./pages/Verify.jsx"));
const Messages = lazy(() => import("./component/Dashboard/Messages.jsx"));
const ResevationDetails = lazy(() => import("./pages/ResevationDetails.jsx"));
const ResevationDetailsForHost = lazy(() => import("./pages/ResevationDetailsForHost.jsx"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy.jsx"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse.jsx"));
const Blogs = lazy(() => import("./pages/Blogs.jsx"));
const Blog = lazy(() => import("./pages/Blog.jsx"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ResevationVerifation = lazy(
  () => import("./pages/ResevationVerifation.jsx")
);

function App() {
  // network checker
  const { showModal, message, closeModal } = useNetworkStatus();


  // Toast  config
  const toastContainerOptions = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progressClassName: "toast-progress-bar",
  };
  const { User } = useContext(Usercontext);
  return (
    <div
      className={`App flex flex-col ${User ? "lg:pb-0 pb-20" : ""}   bg-white`}
    >
     
      <ToastContainer {...toastContainerOptions} />
      <DndProvider backend={HTML5Backend}>
        <Suspense fallback={<RouteLoading />}>
          <Routes>
            <Route path="/:lang?/" element={<Home />} />
            <Route path="/:lang?/property-details/:id" element={<BienDetails />} />
            <Route path="/:lang?/property-details/" element={<BienDetails />} />
            <Route path="/:lang?/auth" element={<Auth />} />
            <Route path="/:lang?/auth/:sub" element={<Auth />} />
            <Route path="/:lang?/auth/:subpages/:id" element={<Auth />} />
            <Route path="/:lang?/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/:lang?/terms-of-use" element={<TermsOfUse />} />
            <Route path="/:lang?/payment" element={<Payment />} />
            <Route path="/:lang?/host" element={<Host />} />
            <Route path="/:lang?/accommodation" element={<Logement />} />
            <Route path="/:lang?/travel-type" element={<TravelType />} />
            <Route path="/:lang?/travel-type/:sub" element={<TravelType />} />
            <Route path="/:lang?/become-host" element={<BecomeHost />} />
            <Route path="/:lang?/become-host/:subpages" element={<BecomeHost />} />
            <Route path="/:lang?/profile" element={<Profile />} />
            <Route path="/:lang?/profile/:subpages" element={<Profile />} />
            <Route path="/:lang?/user-profile/:id" element={<OtherUserProfile />} />
            <Route path="/:lang?/user-profile" element={<OtherUserProfile />} />
            <Route path="/:lang?/dashboard" element={<Dashboard />} />
            <Route path="/:lang?/dashboard/:subpages" element={<Dashboard />} />
            <Route path="/:lang?/dashboard/host-property-details/" element={<HostpropertyDetails />} />
            <Route path="/:lang?/dashboard/host-property-details/:id" element={<HostpropertyDetails />} />
            <Route path="/:lang?/dashboard/calendrier/" element={<Calendrier />} />
            <Route path="/:lang?/dashboard/calendrier/:id" element={<Calendrier />} />
            <Route path="/:lang?/dashboard/:subpages/:id" element={<Dashboard />} />
            <Route path="/:lang?/search-by-map" element={<SearchBienByMap />} />
            <Route path="/:lang?/verify" element={<Verify />} />
            <Route path="/:lang?/verify/:id" element={<Verify />} />
            <Route path="/:lang?/messages/:id"  element={<Messages isTravler={true} />}  />
            <Route path="/:lang?/messages" element={<Messages isTravler={true} />} />
            <Route path="/:lang?/reservation-details"   element={<ResevationDetails />} />
            <Route path="/:lang?/blogs" element={<Blogs />} />
            <Route path="/:lang?/blog" element={<Blog />} />
            <Route path="/:lang?/blog/:id" element={<Blog />} />

            
            <Route path="/:lang?/reservation-details/:id" element={<ResevationDetails />}  />
            <Route path="/:lang?/host/reservation-details/:id"    element={<ResevationDetailsForHost />}     />                                         
            <Route path="/:lang?/reservation-verification" element={<ResevationVerifation />}  />
            <Route path="/:lang?/*" element={<NotFound />} />






            <Route path="/:lang?/partner-details/:id"  element={<PartenairesDetails />}  />
            <Route path="/:lang?/partner-details" element={<PartenairesDetails />} />
            <Route path="/:lang?/friend-trip" element={<FriendTrip />} />

          </Routes>
        </Suspense>
        <MobileMenuBar />
        <Footer />
      </DndProvider>
      <OfflineModal
        show={showModal}
        message={message}
        closeModal={closeModal}
      />
    </div>
  );
}

export default App;
